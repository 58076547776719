<template>
  <form @submit.prevent="updateSitesOfClient">
    <modal-card :title="`Which sites can ${user.email} access?`">
      <loading v-if="loading" />
      <switch-selector
        v-else
        :data="mappedSites"
        :selected="newSelection"
        :disabled="isProcessing"
        placeholder="Filter sites"
      />
      <template slot="footer">
        <button
          v-if="!loading"
          type="submit"
          class="button is-success"
          :class="{ 'is-loading': isProcessing }"
          :disabled="!hasNewSelection || isProcessing"
        >
          Save
        </button>
      </template>
    </modal-card>
  </form>
</template>

<script>
import { collection, getDocs, query, where } from "@firebase/firestore";
export default {
  name: "ManageSitesOfClientModal",
  props: {
    user: {
      type: Object,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    resellerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      isProcessing: false,
      sites: {},
      oldSelection: {},
      newSelection: {}
    };
  },
  computed: {
    mappedSites() {
      return this.$_(this.sites)
        .map(site => {
          return {
            id: site._id,
            label: site.fqdn,
            isDisabled: site.isSubscribed ? false : true,
            isDisabledWhy: "Requires subscription"
          };
        })
        .toArray()
        .value();
    },
    oldSelectionIds() {
      return this.$_(this.oldSelection)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    newSelectionIds() {
      return this.$_(this.newSelection)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    hasNewSelection() {
      return !this.$_.isEqual(this.newSelectionIds, this.oldSelectionIds);
    }
  },
  created() {
    this.getResellerSites();
  },
  methods: {
    getResellerSites() {
      const sitesRef = query(
        collection(this.$firestore, "sites"),
        where("owners", "array-contains", this.resellerId)
      );
      getDocs(sitesRef)
        .then(snapshot => {
          this.$_.each(snapshot.docs, site => {
            this.$set(
              this.sites,
              site.id,
              this.$_.merge({}, site.data(), {
                _id: site.id
              })
            );
          });
        })
        .finally(() => {
          this.$_.each(this.sites, site => {
            if (site.users.includes(this.userId))
              this.$set(this.oldSelection, site._id, true);
          });
          this.$set(this, "newSelection", this.$_.clone(this.oldSelection));
          this.loading = false;
        });
    },
    updateSitesOfClient() {
      this.isProcessing = true;
      this.$store
        .dispatch("reseller/updateSitesOfClient", {
          sites: this.newSelectionIds,
          userId: this.userId,
          resellerId: this.resellerId
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.isProcessing = false;
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
